import React from "react";
import ReactDOM from "react-dom";
import {
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,
} from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import "./Form.scss";
import axios from "axios";
import { backendLink } from "../exports/variable";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <Container id="contForm">
        <Row className="justify-content-md-center">
          <img src={require("../assets/logo.png")} class="logoImg" />
          <div className="vertical"></div>
          <Form name="loginForm" onSubmit={this.handleSubmit}>
            <Form.Text className="text-muted">
              <h1 class="logo-color">byteSense</h1>
              Please reset your password.
            </Form.Text>

            <Form.Group controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={this.state.password}
                placeholder="New Password"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={this.state.confirmPassword}
                placeholder="Confirm Password"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit" class="loginButton">
              Update Password
            </Button>
          </Form>
        </Row>
      </Container>
    );
  }
  handleChange(event) {
    //this sets the updated form values
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();

    //this is called on click of reset password
    const { password, confirmPassword } = this.state;
    console.log(backendLink + "user" + window.location.pathname);
    axios
      .put(backendLink + "user" + window.location.pathname, {
        password,
        confirmPassword,
      })
      .then((response) => {
        //this.setState({errorMessage:"Email is sent successfully to the email account."});
        this.props.history.push("/");
      })
      .catch((err) => {
        this.setState({ errorMessage: "Please try again!!" });
        //console.log(err.response.status);
        //console.log(err.response.headers);
        // this.props.history.push("/");
      });
    //this.props.history.push("/home");
  }
}
ReactDOM.render(<ResetPassword />, document.getElementById("root"));
export default ResetPassword;
