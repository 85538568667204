import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

import HomePage from "../pages/HomePage";
import Register from "../pages/Register";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
// import PatientRequest from "../components/homepageContent/patientRequest";
// import PatientRequestContent from "../pages/PatientRequestContent";
// import PatientListContent from "../pages/PatientListContent";
import DentistProfile from "../pages/DentistProfile";
import BrokenPage from "../pages/BrokenPage"
import axios from "axios";
import { backendLink } from '../exports/variable';

class RouterControl extends Component {
  tokenCheck = async () => {
    const token = localStorage.getItem("token");
   
    axios
      .get(backendLink + "user/profile", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })

      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  checkAuth = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return false;
    }

    let tokenStatus = this.tokenCheck();

    if (tokenStatus) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const AuthRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.checkAuth() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/login" }} />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/reset/:id" component={ResetPassword} />
          <AuthRoute exact path={["/request", "/list", "/", "/chat", "/alerts", "/help"]} component={HomePage} />
          {/* <AuthRoute
            exact
            path="/patientRequest"
            component={PatientRequestContent}
          />
          <AuthRoute exact path="/patientlist" component={PatientListContent} /> */}
          <AuthRoute exact path="/profile" component={DentistProfile} />
          <Route component={BrokenPage} />
        </Switch>
      </Router>
    );
  }
}

export default RouterControl;
