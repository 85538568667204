import React, { Component } from "react";
import "./homepageContent.scss";
import {
  Spinner,
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import { updatePatientID } from "../../actions/PageActions";
import { updatePatientDetail } from "../../actions/APIAction";
import { withRouter } from "react-router-dom";
import { backendLink } from "../../exports/variable";
import { doctor } from "../../exports/apiCalls";
class PatientList extends Component {
  currentPage = 1
  pageSize = 10
  state = {
    buttonDisabled: false,
    show: false,
    loading: true,
    currentPatientInfo: {
      id: "N/A",
      Patient: "N/A",
      DOB: "N/A",
      Place_of_Residence: "N/A",
      Country: "N/A",
      PhoneNumber: "N/A",
      Gender: "N/A",
      img: (
        <span id="gct_account_circle" className="material-icons">
          account_circle
        </span>
      ),
    },
    selectRow: {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { background: "#0032F3" },
    },

    columns: [
      {
        dataField: "Patient",
        text: "Patient",
      },
      { dataField: "DOB", text: "DOB", headerAlign: "center", align: "center" },
      {
        dataField: "Place_of_Residence",
        text: "Place of Residence",
        headerAlign: "center",
        align: "center",
      },
      // {
      //   dataField: "Last_Visited",
      //   text: "Last Visited",
      //   headerAlign: "center",
      //   align: "center",
      // },
      // {
      //   dataField: "Next_Appointment",
      //   text: "Next Appointment",
      //   headerAlign: "center",
      //   align: "center",
      // },
      // {
      //   dataField: "Bruxism_Level",
      //   text: "Bruxism Level",
      //   headerAlign: "center",
      //   align: "center",
      // },
    ],

    products: [],
    pagination: paginationFactory({
      hideSizePerPage: true,
      onPageChange: (page, sizePerPage) => {
        this.currentPage = page
      },
      sizePerPage: this.pageSize,
    }),
    rowEvents: {
      onMouseEnter: (e, row, rowIndex)  => {
        let pageAdder = this.pageSize*(this.currentPage-1)
        this.setState({ currentPatientInfo: this.state.products[rowIndex+pageAdder]});
      },
      onClick:  (e, row, rowIndex) => {
        this.setState({loading: true})
        console.log(row.id)
        this.props.updatePatientID({ patientDataUser: row.id });
        this.props.updatePatientDetail(row.id).then(results => {
          if(results){
            window.location.href = "/list#report";
          }
        })

      },
    },
  };


  colorDiv(text) {
    switch (text) {
      case "High":
        return <div style={{ color: "#023CF7" }}>High</div>;
        break;
      case "Med":
        return <div style={{ color: "#5D82FA" }}>Med</div>;
        break;
      case "Low":
        return <div style={{ color: "#99ECFA" }}>Low</div>;
        break;
      default:
        return "";
    }
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    {
      doctor
        .doctorRequests()
        .getPatientList()
        .then((response) => {
          let tempArray = [];
          let NA = "N/A";
          for (let value of response.data.patientList) {
            let tempObject = {
              id: !!value.new_patient._doc.user
                ? value.new_patient._doc.user
                : NA,
              Patient:
                !!value.new_patient._doc.fName || !!value.new_patient._doc.lName
                  ? value.new_patient._doc.fName +
                    " " +
                    value.new_patient._doc.lName
                  : NA,
              DOB: !!value.new_patient._doc.dob
                ? value.new_patient._doc.dob
                : NA,
              Place_of_Residence: !!value.new_patient._doc.address.state
                ? value.new_patient._doc.address.state
                : NA,

              // Last_Visited: !!value.new_patient.LastVisited
              //   ? value.new_patient.LastVisited
              //   : NA,

              // Next_Appointment: !!value.new_patient.NextAppointment
              //   ? value.new_patient.NextAppointment
              //   : NA,
              // Bruxism_Level: !!value.new_patient.BruxismLevel
              //   ? this.colorDiv(value.new_patient.BruxismLevel)
              //   : NA,
              Country: !!value.new_patient._doc.address.country
                ? value.new_patient._doc.address.country
                : NA,
              PhoneNumber: !!value.new_patient._doc.phone
                ? value.new_patient._doc.phone
                : NA,
              Gender: !!value.new_patient._doc.gender
                ? value.new_patient._doc.gender
                : NA,
              img: !!value.new_patient._doc.picture ? (
                <img
                  className="circular--square"
                  src={
                    backendLink +
                    "Uploads/profilePictures/" +
                    value.new_patient._doc.picture
                  }
                  alt={"Profile Pic"}
                />
              ) : (
                <span id="gct_account_circle" className="material-icons">
                  account_circle
                </span>
              ),
              buttonDisabled: true,
            };
            tempArray.push(tempObject);
          }

          this.setState({
            products: tempArray,
            loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  deletePatient = async () => {
    doctor
      .doctorRequests()
      .deletePatient(this.state.currentPatientInfo.id)
      .catch((error) => {
        console.log(error);
      });
    window.location.reload();
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  render() {
    const { SearchBar } = Search;
    //console.log(this.state.pagination.page)
    return (
      <div className="homePageContent" id="PatientList">
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove{" "}
            {this.state.currentPatientInfo.Patient}. This process cannot be
            undone.{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={this.deletePatient}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={this.state.columns}
          search
        >
          {(props) => (
            <div id="patientListTable">
              <div>
                <Row>
                  <Col>
                    <div id="patientListTitle">Patient List</div>
                  </Col>
                  <Col>
                    <SearchBar {...props.searchProps} />
                  </Col>
                </Row>
              </div>
              <div hidden={!this.state.loading} className="loadingContainer">
                <Spinner size="lg" animation="border" />
                <div>Loading...</div>
              </div>
              <div hidden={this.state.loading} className="grid-container">
                <Card className="grid-child-one">
                  <div className="patientList_container">
                    <BootstrapTable
                      hover
                      pagination={this.state.pagination}
                      bordered={false}
                      rowEvents={this.state.rowEvents}
                      selectRow={this.state.selectRow}
                      {...props.baseProps}
                    />
                  </div>
                </Card>
            {
              /*
                <Card className="grid-child-two">
                  <div id="gct_title">Patient Info</div>
                  <div className="grid-container-two">
                    <div>{this.state.currentPatientInfo.img}</div>
                    <div>
                      <div className="gct_name">
                        {this.state.currentPatientInfo.Patient}
                      </div>
                      <div className="gct_description">
                        {this.state.currentPatientInfo.Country}
                      </div>
                      <div className="gct_description">
                        Moblie: {this.state.currentPatientInfo.PhoneNumber}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col font-weight-bold">DOB:</div>
                      <div className="col-text-left">
                        {this.state.currentPatientInfo.DOB}{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col font-weight-bold">Gender:</div>
                      <div className="col-text-left">
                        {this.state.currentPatientInfo.Gender}{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col font-weight-bold">
                        Place of Residence:
                      </div>
                      <div className="col-text-left">
                        {this.state.currentPatientInfo.Place_of_Residence}
                      </div>
                    </div>
                  </div>
                  <Dropdown id="patient_dropdown">
                    {/* <Dropdown.Toggle
                      variant="outline-dark"
                      block
                      id="dropdown-basic"
                    >
                      Notes
                    </Dropdown.Toggle> 
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    className="mb-4"
                    style={{ visibility: "hidden" }}
                    id="Contact_Patient"
                  >
                    Contact{" "}
                    <span id="mail_button" className="material-icons">
                      mail_outline
                    </span>
                  </Button>
                  <Button
                    disabled={!this.state.currentPatientInfo.buttonDisabled}
                    onClick={this.handleShow}
                    className="mt-0 mb-4"
                    id="Remove_Patient"
                  >
                    Remove Patient{" "}
                  </Button>
                </Card>
                */
          }
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatientID: (value) => dispatch(updatePatientID(value)),
  updatePatientDetail: (value) => dispatch(updatePatientDetail(value))

});

export default connect(null, mapDispatchToProps)(withRouter(PatientList));
