import React, { Component } from "react";
import "./homepageContent.scss";
import { Row, Container, Col, Card, Dropdown, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { doctor } from "../../exports/apiCalls";

class PatientRequest extends Component {
  state = {
    selectRow: {
      mode: "radio",
      clickToSelect: true,
      hideSelectColumn: true,
      style: { background: "#FCF5EC" },
    },

    columns: [
      {
        dataField: "fName",
        text: "First Name",
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "lName",
        text: "Last Name",
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Action",
        text: "Action",
        headerAlign: "center",
        align: "center",
      },
      {
        dataField: "Action2",
        text: "",
        headerAlign: "center",
        align: "center",
      },
    ],

    products: [],
    pagination: paginationFactory({
      hideSizePerPage: true,
    }),
  };
  componentDidMount() {
    //this is called and sets the list of patient requests
    doctor
      .doctorRequests()
      .getpatientRequest()
      .then((response) => {
        //response.data.s
        if (response.data.length == 0) {
          response.data = [{}];
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i]["Action"] = (
              <button
                onClick={() =>
                  this.handleAccept(response.data[i]["user"], "Accept")
                }
              >
                Accept
              </button>
            );
            response.data[i]["Action2"] = (
              <button
                className="declineButton"
                onClick={() =>
                  this.handleAccept(response.data[i]["user"], "Decline")
                }
              >
                Decline
              </button>
            );
          }
        }
        this.setState({ products: response.data });
      });
  }
  handleAccept(patient, action) {
    //this is called when doctor wants to accept the patient request
    if (action == "Accept") {
      doctor
        .doctorRequests()
        .addPatient(patient)
        .then((response) => {
          this.componentDidMount();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //this is called when doctor wants to decline the patient request
    else {
      doctor
        .doctorRequests()
        .declinePatient(patient)
        .then((response) => {
          this.componentDidMount();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  render() {
    return (
      <div className="homePageContent" id="PatientList">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={this.state.columns}
          search
        >
          {(props) => (
            <div id="patientListTable">
              <Container>
                <Row>
                  <Col>
                    <div id="patientListTitle">Patient Request</div>
                  </Col>
                </Row>
              </Container>
              <div className="request">
                <Card className="grid-child-one">
                  <div className="patientList_container">
                    <p>
                      Please provide your patient(s) with your{" "}
                      <mark className="markText">Observer ID</mark>, which can be
                      found on your <mark className="markText">Profile</mark>{" "}
                      page so they can enter it in their{" "}
                      <mark className="markText">Observers</mark> Page.
                    </p>
                    <BootstrapTable
                      hover
                      pagination={this.state.pagination}
                      bordered={false}
                      rowEvents={this.state.rowEvents}
                      selectRow={this.state.selectRow}
                      {...props.baseProps}
                    />
                  </div>
                </Card>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default PatientRequest;
