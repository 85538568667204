import React from "react";
import ReactDOM from "react-dom";
import {
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,
} from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import "./Form.scss";
import { backendLink } from '../exports/variable';
import axios from "axios";
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  render() {
    return (
      <Container id="contForm">
        <Row className="justify-content-md-center">
          <img src={require("./image.png")} class="logoImg" />
          <div className="vertical"></div>
          <Form name="ForgotForm" onSubmit={this.handleSubmit}>
            <Form.Text className="text-muted">
              <h1 class="logo-color">byteSense</h1>
              Enter your email and we send you a password reset link.
            </Form.Text>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Group>
            {this.state.errorMessage && (
              <p className="success"> {this.state.errorMessage} </p>
            )}
            <Button variant="primary" type="submit" class="loginButton">
              Send Request
            </Button>
          </Form>
        </Row>
      </Container>
    );
  }
  handleChange(event) {
    //this sets the updated form values
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  handleSubmit(event) {
    //this is called when user clicks on forgot password
    event.preventDefault();
    const { email } = this.state;
    axios
      .post(backendLink + "user/forgotpassword", { email })
      .then((response) => {
        this.setState({
          errorMessage: "Email is sent successfully to the email account.", email: "",
        });
        // this.props.history.push("/resetPassword");
      })
      .catch((err) => {
        this.setState({ errorMessage: "Please try again!!", email: "" });
        //console.log(err.response.status);
        //console.log(err.response.headers);
        // this.props.history.push("/");
      });
    //this.props.history.push("/home");
  }
}
ReactDOM.render(<ForgotPassword />, document.getElementById("root"));
export default ForgotPassword;
