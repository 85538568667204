import { combineReducers } from "redux";
// import { createSelector } from 'reselect';
import pageReducer from "./PageReducer";

export default combineReducers({
  page: pageReducer,
  
});

export const getPatientId = (state) => state.page.patientId;
export const getPatient = (state) => state.page.patient;
export const getDentistDetail = (state) => state.page.dentistDetail;
